import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout'
import SEO from 'components/shared/SEO'

import Hero from 'components/layout/Sections/Pages/404/Hero'

const NotFoundPage: React.FC<PageProps> = ({ data }) => {
  return (
    <Layout>
      <SEO title={'Gatigo ⇒ 404'} />
      <Hero
        imgMobile={data.heroImgMobile.childImageSharp.gatsbyImageData}
        imgDesktop={data.heroImgDesktop.childImageSharp.gatsbyImageData}
      />
    </Layout>
  )
}

export default NotFoundPage

export const query = graphql`
  query NotFoundPage {
    heroImgMobile: file(name: { eq: "404-img-mobile" }) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, formats: [AUTO, WEBP])
      }
    }
    heroImgDesktop: file(name: { eq: "404-img-desktop" }) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, formats: [AUTO, WEBP])
      }
    }
  }
`
