import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import LazyImage from 'components/shared/lazyImage'
import Button from 'components/shared/button'
import { Text } from 'components/shared/typography'
import Container from 'components/shared/container'

import { ImageDataLike } from 'gatsby-plugin-image'

type Props = {
  imgMobile: ImageDataLike
  imgDesktop: ImageDataLike
}

const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;

  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  padding: 20px;

  .background-image--desktop {
    display: none;
  }

  ${({ theme }) => theme.media.lg.min} {
    padding: 0;

    .background-image--desktop {
      display: block;
    }

    .background-image--mobile {
      display: none;
    }
  }
`

const StyledContainer = styled(Container)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const InfoWrapper = styled.div`
  align-self: flex-end;
  padding-bottom: 20vh;

  ${({ theme }) => theme.media.lg.min} {
    padding-bottom: 0;
  }
`

const StyledInfoText = styled(Text)`
  font-size: 24px;
  font-weight: 500;

  ${({ theme }) => theme.media.xxl.min} {
    font-size: 35px;
  }
`
const StyledStatusCode = styled(Text)`
  font-size: 120px;
  font-weight: 500;

  ${({ theme }) => theme.media.xxl.min} {
    font-size: 200px;
  }
`

const StyledButton = styled(Button)`
  text-transform: uppercase;
  padding: 25px 25px;
  text-align: center;

  position: absolute;
  bottom: 60px;
  left: 16px;
  right: 16px;

  ${({ theme }) => theme.media.lg.min} {
    position: static;
    padding: 20px 25px;
    align-self: flex-end;
  }

  ${({ theme }) => theme.media.xxl.min} {
    font-size: 20px;
  }
`

const Hero: React.FC<Props> = ({ imgMobile, imgDesktop }) => {
  return (
    <Wrapper>
      <LazyImage
        src={imgDesktop}
        alt=""
        className="background-image background-image--desktop"
      />

      <LazyImage
        src={imgMobile}
        alt=""
        className="background-image background-image--mobile"
      />

      <StyledContainer>
        <InfoWrapper>
          <StyledInfoText>Nie znaleziono strony</StyledInfoText>
          <StyledStatusCode>404</StyledStatusCode>
        </InfoWrapper>
        <StyledButton primary as={Link} to="/">
          Wróć do strony głównej
        </StyledButton>
      </StyledContainer>
    </Wrapper>
  )
}

export default Hero
